/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Děti v akci "}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1ekb0cc --style4 js-anim  --anim3 --full" anim={"3"} name={"7h5wrz0vjs9"} style={{"paddingTop":33,"paddingBottom":0}} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/ff475403da0a408785ef15e7431a2867_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/ff475403da0a408785ef15e7431a2867_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/ff475403da0a408785ef15e7431a2867_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/ff475403da0a408785ef15e7431a2867_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/ff475403da0a408785ef15e7431a2867_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/ff475403da0a408785ef15e7431a2867_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/ff475403da0a408785ef15e7431a2867_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/ff475403da0a408785ef15e7431a2867_s=3000x_.jpg);
    }
  
    `}>
        </Column>


        <Column name={"zwmgputxhsb"} style={{"marginTop":0,"paddingTop":13,"paddingBottom":12,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--1 fs--36" content={"<span style=\"font-weight: bold; color: var(--black);\"><br>Vítejte na oficiálních stránkách organizace Děti v akci !&nbsp;</span>"}>
              </Title>

              <Text className="text-box fs--22" style={{"maxWidth":650}} content={"<span style=\"font-weight: bold; color: rgb(246, 5, 5);\">\"MĚNÍME SVĚT DĚTSKÝCH DOMOVŮ K LEPŠÍMU.\"&nbsp;</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box ff--1 fs--16" content={"<span style=\"font-weight: bold;\">Ladislav Samek - ředitel a zakladatel organizace<br><br></span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-d50ao0 css-42e4bw --full pb--60 pt--60" name={"35nmp82ydn1"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/b5f6a4747e2f4fb59cc6c60ec365c187_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/b5f6a4747e2f4fb59cc6c60ec365c187_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/b5f6a4747e2f4fb59cc6c60ec365c187_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/b5f6a4747e2f4fb59cc6c60ec365c187_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/b5f6a4747e2f4fb59cc6c60ec365c187_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/b5f6a4747e2f4fb59cc6c60ec365c187_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/b5f6a4747e2f4fb59cc6c60ec365c187_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/b5f6a4747e2f4fb59cc6c60ec365c187_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"j3iz44hnm9q"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: rgb(245, 35, 5);\">Děkujeme za vše pane profesore a nikdy nezapomeneme !&nbsp;</span>"}>
              </Title>

              <Text className="text-box text-box--justify fs--18" content={"<span style=\"color: var(--black); font-weight: bold;\">Láska, láska, láska…vlastně nic jiného. Pečujte o vztahy, protože jedině vztahy vám dají dobrý život. Můžete dobře jíst, sportovat, ale když nemáte vztahy, je to stejně jedno. Pečujte o toho druhého víc než o sebe… \n\nA jestli bych měl definovat to, co v životě opravdu zabíjí, je to samota… Samota vás zničí…pomalu a spolehlivě. \n\nA k mým oblíbeným tématům: opravdu nebijte děti,opravdu je to naprosto zbytečný, jde to a lépe i bez toho…nezapomeňte, že děti by se s vámi měly cítit bezpečně…ne se bát, kdy zase nějaká přiletí.<br></span>"}>
              </Text>

              <Text className="text-box text-box--right fs--18" content={"<span style=\"color: var(--black); font-weight: bold;\">&nbsp;Prof. PhDr. et PhDr. Radek Ptáček, Ph.D. </span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"onas"} style={{"marginTop":0,"paddingTop":0,"paddingBottom":22,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --left el--3 --full flex--top" style={{"backgroundColor":"var(--white)"}} columns={"3"} fullscreen={true}>
            
            <ColumnWrapper className="js-anim  --anim4 --justify" anim={"4"}>
              
              <Image src={"https://cdn.swbpg.com/t/17142/cf57ad0b94a04bf9b3a8c9c24dd9d7e7_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, 33vw" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/cf57ad0b94a04bf9b3a8c9c24dd9d7e7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/cf57ad0b94a04bf9b3a8c9c24dd9d7e7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/cf57ad0b94a04bf9b3a8c9c24dd9d7e7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/cf57ad0b94a04bf9b3a8c9c24dd9d7e7_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17142/cf57ad0b94a04bf9b3a8c9c24dd9d7e7_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Title className="title-box title-box--center fs--20" content={"<span style=\"font-weight: bold; color: rgb(243, 6, 6);\">dětství strávené v institucích ústavní péče</span>"}>
              </Title>

              <Text className="text-box text-box--justify" style={{"maxWidth":518}} content={"<span style=\"color: var(--black); font-weight: bold;\">Ředitelem a zakladatelem organizace je Ladislav Samek, který má bohaté osobní zkušenosti s ústavní i náhradní rodinnou péčí. Od narození až do svých 18 let prošel šesti dětskými ústavními zařízeními a po svém odchodu v 18 letech se rozhodl dát dohromady partu mladých lidí s podobnými osudy a začít o věcech za zdmi ústavních zařízení mluvit otevřeně a nahlas.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4" anim={"4"}>
              
              <Image src={"https://cdn.swbpg.com/t/17142/7ee26bb73e5a40b4b8ed93f406ac61f3_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, 33vw" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/7ee26bb73e5a40b4b8ed93f406ac61f3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/7ee26bb73e5a40b4b8ed93f406ac61f3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/7ee26bb73e5a40b4b8ed93f406ac61f3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/7ee26bb73e5a40b4b8ed93f406ac61f3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17142/7ee26bb73e5a40b4b8ed93f406ac61f3_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Title className="title-box title-box--center fs--20" content={"<span style=\"font-weight: bold; color: rgb(249, 37, 6);\">chceme být správným vzorem a garancí jistoty</span>"}>
              </Title>

              <Text className="text-box text-box--justify" style={{"maxWidth":521}} content={"<span style=\"color: var(--black); font-weight: bold;\">Snažíme se dětem na nás samotných ukázat, že to jde! Že i přes všechny životní indispozice to jde zvládnout a proto se je snažíme intenzivně podporovat, být jim na blízku a pomáhat jim v jejich životní cestě. Zároveň chceme, aby měli jistotu, že tu pro ně bude někdo, kdo je nebude odsuzovat, ale kdo jim podá pomocnou ruku a nabídne pomoc v době, kdy to budou nejvíce potřebovat!&nbsp;&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4" anim={"4"}>
              
              <Image src={"https://cdn.swbpg.com/t/17142/b567600dfd4947cdb463476bc1334b07_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, 33vw" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17142/b567600dfd4947cdb463476bc1334b07_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/b567600dfd4947cdb463476bc1334b07_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/b567600dfd4947cdb463476bc1334b07_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/b567600dfd4947cdb463476bc1334b07_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17142/b567600dfd4947cdb463476bc1334b07_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Title className="title-box title-box--center fs--20" content={"<span style=\"font-weight: bold; color: rgb(241, 39, 10);\">každé dítě si zaslouží šanci a ne jednu&nbsp;</span>"}>
              </Title>

              <Text className="text-box text-box--justify" style={{"maxWidth":517}} content={"<span style=\"color: var(--black); font-weight: bold;\">Největší důraz klademe na rozvoj dětského potenciálu, talentu a seberealizaci dětí v jejich okolí. Víme ale, že řada dětí nemá tu možnost zdokonalovat se ve svém talentu a proto se snažíme právě tyto děti podporovat v rámci našich projektů, aby si tak mohly plnit svoje sny, protože věříme, že i sny se dají přetvořit v realitu a v té by měly děti žít nejvíce.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}